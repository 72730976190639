export const environment = {
    "ewalletAPI": "https://gbetsapi.easygamesdemo.co.za/",
    "Authentication": "api/Authentication/Login",
    "GetTransactions": "api/Transaction/GetTransactions",
    "GetTransactionCount": "api/Transaction/GetTransactionCount",
    "ReverseTransactions": "api/Transaction/Reverse",
    "PasswordReset": "api/User/PasswordReset",
    "Logout": "api/Authentication/Logout",
    "Logo": "../../assets/Images/gbets-removebg-preview.png",
    "DownloadTransactionReport": "api/Reports/DownloadTransactionReport",
    "OperatorName": "GBets"
};
